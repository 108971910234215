import styled, { keyframes } from "styled-components";

const ShimmerKeyframes = keyframes`
    	100% {
			transform: translateX(100%);
		}
`;

interface SkeletonProps {
	height?: string;
	width?: string;
	rounded?: boolean;
	borderRadius?: string;
	bgcolor?: string;
	opacity?: string;
}

const Skeleton = styled.div<SkeletonProps>`
	display: inline-block;
	height: ${(props) => props.height || "1em"};
	width: ${(props) => props.width || "100%"};
	position: relative;
	overflow: hidden;
	background-color: ${(props) => props.bgcolor || "#dddbdd"};
	border-radius: ${(props) => (props.rounded ? props.borderRadius : "0")};
	opacity: ${(props) => props.opacity || 1}

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: "";
		animation: ${ShimmerKeyframes} 2s infinite;
		transform: translateX(-100%);
		background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	}
`;

export default Skeleton;
